import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #212835;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 14px;
  width: 300px;
`;

const Bar = styled.div`
  background: linear-gradient(to left, #d44343, #7d1111);
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`;

export { Wrapper, Bar };
