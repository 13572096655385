import React, { useRef, useState, useEffect } from 'react';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Controller, Scene } from 'react-scrollmagic';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles.css';
import { StyledSequence, LoadingWrapper } from './index.styles';
import Sequence from '../../components/Sequence';
import Home from '../../components/Home';
import SkillSet from '../../components/SkillSet';
import Portfolio from '../../components/Portfolio';
import Footer from '../../components/Footer';
import AboutMe from '../../components/AboutMe';
import Header from '../../components/Header';
import GetInTouch from '../../components/GetInTouch';
import { deviceSize } from '../../constants';
import { useWindowSize } from '../../utils/window';
import { Overlay } from '../../styles';
import ProgressBar from '../../components/ProgressBar';
import WebFont from 'webfontloader';

gsap.registerPlugin(ScrollTrigger);

const HomeContainer = () => {
  const [loading, setLoading] = useState(true);
  const [style, setStyle] = useState({});
  const refHome = useRef(null);
  const refSkillSet = useRef(null);
  const refPortfolio = useRef(null);
  const refSequence = useRef(null);
  const refGetInTouch = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    const initiate = async () => {
      await WebFont.load({
        custom: {
          families: [
            'Avenir Light',
            'Avenir Roman',
            'Avenir Oblique',
            'Avenir Heavy',
            'Avenir Black',
            'GTWalsheimProBold',
          ],
        },
      });
      const newStyle = { opacity: 1, width: '100%' };
      setStyle(newStyle);

      setTimeout(() => {
        const newStyle = { opacity: 0, width: '100%' };
        setStyle(newStyle);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    initiate();
  }, []);

  return (
    <div className="App">
      {loading ? (
        <LoadingWrapper>
          <ProgressBar style={style} />
          <p style={style}>Loading...</p>
        </LoadingWrapper>
      ) : (
        <>
          <Header />
          <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
            <Scene>
              <Home ref={refHome} />
            </Scene>
            <Scene>
              <SkillSet ref={refSkillSet} />
            </Scene>
            {width <= deviceSize.medium ? (
              <StyledSequence>
                <AboutMe />
              </StyledSequence>
            ) : (
              <Scene
                duration="100%"
                triggerHook="onLeave"
                pin
                triggerElement="#image-wrapper"
                offset="100px"
              >
                {(progress) => (
                  <StyledSequence>
                    <AboutMe />
                    <Overlay />
                    <Sequence ref={refSequence} progress={progress} />
                  </StyledSequence>
                )}
              </Scene>
            )}
            <Scene>
              <Portfolio ref={refPortfolio} />
            </Scene>
            <Scene>
              <GetInTouch ref={refGetInTouch} />
            </Scene>
          </Controller>
          <Footer />
        </>
      )}
    </div>
  );
};

export default HomeContainer;
