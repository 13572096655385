import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import {
  StyledContainer,
  Wrapper,
  IconsWrapper,
  Circle,
  CircleImg,
  CircleText,
  CircleWrapper,
  TextWrapper,
  Title,
  Paragraph,
  UnderText,
  Dot,
  Overlay,
} from './index.styles';
import CleanCodeImg from '../../assets/images/clean-code.png';
import PixelPerfectImg from '../../assets/images/pixel-perfect.png';
import UXImg from '../../assets/images/ux.png';
import LoveImg from '../../assets/images/with-love.png';
import { useWindowSize } from '../../utils/window';
import { deviceSize } from '../../constants';

const AboutMe = () => {
  const wrapper = useRef(null);
  const item1 = useRef(null);
  const item2 = useRef(null);
  const item3 = useRef(null);
  const item4 = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width > deviceSize.medium) {
      gsap.to('#about-me-wrapper', {
        y: -200,
        ease: 'slow',
        scrollTrigger: {
          trigger: '#about-me-wrapper',
          toggleActions: 'play none none reverse',
          scrub: true,
          start: 'center center',
        },
      });
    }
    gsap.from('#item1', {
      y: -10,
      opacity: 0,
      duration: 1,
      ease: 'power1',
      delay: 1,
    });
    gsap.from('#item2', {
      y: -10,
      opacity: 0,
      duration: 2,
      ease: 'power1',
      delay: 2,
    });
    gsap.from('#item3', {
      y: -10,
      opacity: 0,
      duration: 3,
      ease: 'power1',
      delay: 2.5,
    });
    gsap.from('#item4', {
      y: -10,
      opacity: 0,
      duration: 5,
      ease: 'power1',
      delay: 3,
    });
  }, []);

  return (
    <StyledContainer id="about-me-container">
      <Wrapper ref={wrapper} id="about-me-wrapper">
        <IconsWrapper>
          <CircleWrapper ref={item1} id="item1">
            <Circle>
              <CircleImg src={CleanCodeImg} />
            </Circle>
            <CircleText>Clean Code</CircleText>
          </CircleWrapper>
          <CircleWrapper ref={item2} id="item2">
            <Circle>
              <CircleImg src={PixelPerfectImg} />
            </Circle>
            <CircleText>Pixel Perfect</CircleText>
          </CircleWrapper>
          <CircleWrapper ref={item3} id="item3">
            <Circle>
              <CircleImg src={UXImg} />
            </Circle>
            <CircleText>User Experience</CircleText>
          </CircleWrapper>
          <CircleWrapper ref={item4} id="item4">
            <Circle>
              <CircleImg src={LoveImg} />
            </Circle>
            <CircleText>Made with Love</CircleText>
          </CircleWrapper>
        </IconsWrapper>
        <TextWrapper>
          <Title>
            I love new challenges,
            <br /> I'm looking for creative and innovative projects
            <Dot />
          </Title>
          <Paragraph>
            Every project is a new opportunity for learning new things. I’ve always been passionate
            about meeting new people, seeing other ways to do my job, learning about new markets and
            technologies. I enjoy pursuing perfection in all projects.
          </Paragraph>
          <UnderText>
            Our job is to
            <br />
            create successful
            <br />
            experiences
          </UnderText>
        </TextWrapper>
      </Wrapper>
      {width <= deviceSize.medium && <Overlay />}
    </StyledContainer>
  );
};

export default AboutMe;
