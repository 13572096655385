import React, { forwardRef, LegacyRef } from 'react';
import {
  StyledContainer,
  Title,
  Wrapper,
  SeeMoreBtn,
  Paragraph,
  Label,
  LinksWrapper,
  Link,
} from './index.styles';
import Github from '../../assets/images/github.png';
import Linkedin from '../../assets/images/linkedin.png';
import Whatsapp from '../../assets/images/whatsapp.png';

const GetInTouch = forwardRef((props, ref) => {
  return (
    <div ref={ref as LegacyRef<HTMLDivElement> | null}>
      <StyledContainer>
        <Wrapper>
          <Label>Contact</Label>
          <Title>Let's do something together</Title>
          <Paragraph>
            Are you looking for a passionate frontend developer to join your team? Don't be shy! I
            would love to hear more about your project.
          </Paragraph>
          <LinksWrapper>
            <Link
              href="https://www.linkedin.com/in/florenciacalomino/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="Linkedin" />
            </Link>
            <Link
              href="https://github.com/florcalomino?tab=repositories"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Github} alt="Github" />
            </Link>
            <Link
              href="https://api.whatsapp.com/send?phone=541154814422&text=Lets%20do%20something%20together!"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Whatsapp} alt="Whatsapp" />
            </Link>
          </LinksWrapper>
          <SeeMoreBtn
            href="mailto:florcalomino@icloud.com?subject=Lets%20do%20something%20together!"
            target="_blank"
          >
            Send an Email
          </SeeMoreBtn>
        </Wrapper>
      </StyledContainer>
    </div>
  );
});

export default GetInTouch;
