import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { MainWrapper, Text } from '../../styles';

const StyledHeader = styled.div`
  color: white;
  align-items: center;
  display: flex;
  padding: 1rem 0 10vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(55%, rgba(0, 0, 0, 0.3)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;

const Wrapper = styled(MainWrapper)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
`;

const Label = styled(Text)`
  text-align: center;
  margin-bottom: 0;
  margin: auto;
  font-size: 0.9rem;
`;

const MenuWrapper = styled.div`
  display: flex;
`;

const Logo = styled.img`
  height: 28px;
`;

const Link = styled.a`
  width: 22px;
  margin-left: 1.3rem;

  &:hover {
    img {
      opacity: 0.6;
    }
  }

  img {
    transition: all 0.4s;
    width: 100%;
  }
`;

export { StyledHeader, Wrapper, Label, Logo, MenuWrapper, Link };
