import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { Button, MainWrapper, Text } from '../../styles';

const StyledHome = styled.div`
  background-color: black;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 5rem 0 0;
`;

const Wrapper = styled(MainWrapper)`
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-bottom: 10vh;

  @media (max-width: ${deviceSize.medium}px) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 45%;
  text-align: left;
  z-index: 1;

  @media (max-width: ${deviceSize.medium}px) {
    width: 100%;
    order: 1;
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  color: white;
  font-size: 4rem;
  text-align: left;
  margin-bottom: 0;

  @media (min-width: ${deviceSize.extraLarge}px) {
    font-size: 5rem;
  }

  @media (max-width: ${deviceSize.medium}px) {
    font-size: 3rem;
  }
`;

const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.typography.black};
  color: white;
  font-size: 4rem;
  opacity: 0.5;
  text-align: left;
  margin: 0; 
  white-space: nowrap;

  @media (min-width: ${deviceSize.extraLarge}px) {
    font-size: 5rem;
  }


  @media (max-width: ${deviceSize.medium}px) {
    font-size: 3rem;
    white-space: pre-wrap;
  }
}
`;

const Paragraph = styled(Text)`
  margin-top: 1rem;
`;

const SeeMoreBtn = styled(Button)`
  margin-top: 2rem;
`;

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  display: inline-block;
  position: relative;
  left: 4px;
  top: 3px;

  @media (max-width: ${deviceSize.medium}px) {
    width: 10px;
    height: 10px;
  }
`;

const EyesWrapper = styled.div`
  max-width: 40%;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 5vh;
  opacity: 1;

  @media (max-width: ${deviceSize.medium}px) {
    position: relative;
    order: 0;
    top: 0;
    max-width: 60%;
    align-self: flex-start;
  }
`;

const EyesImage = styled.img`
  width: 100%;
`;

export {
  StyledHome,
  Title,
  Wrapper,
  SubTitle,
  SeeMoreBtn,
  Dot,
  EyesWrapper,
  EyesImage,
  Paragraph,
  TextWrapper,
};
