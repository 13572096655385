const typography = {
  light: 'Avenir Light, sans-serif',
  roman: 'Avenir Roman, sans-serif',
  oblique: 'Avenir Oblique, sans-serif',
  heavy: 'Avenir Heavy, sans-serif',
  black: 'Avenir Black, sans-serif',
  GTWalsheimProBold: 'GTWalsheimProBold, sans-serif',
};

export default typography;
