import { createGlobalStyle } from 'styled-components';
import AvenirLTStdLight from '../assets/fonts/AvenirLTStd-Light.ttf';
import AvenirLTStdRoman from '../assets/fonts/AvenirLTStd-Roman.ttf';
import AvenirLTStdOblique from '../assets/fonts/AvenirLTStd-Oblique.ttf';
import AvenirLTStdHeavy from '../assets/fonts/AvenirLTStd-Heavy.ttf';
import AvenirLTStdBlack from '../assets/fonts/AvenirLTStd-Black.ttf';
import GTWalsheimProBold from '../assets/fonts/GTWalsheimPro-Bold.ttf';
import { ThemeProps } from './types';

export const fontLight = AvenirLTStdLight;
export const fontHeavy = AvenirLTStdHeavy;

const GlobalStyle = createGlobalStyle<{ theme: ThemeProps }>`
@font-face {
    font-family: 'Avenir Roman';
    src: url(${AvenirLTStdRoman}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir Light';
    src: url(${AvenirLTStdLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir Oblique';
    src: url(${AvenirLTStdOblique}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Avenir Heavy';
    src: url(${AvenirLTStdHeavy}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir Black';
    src: url(${AvenirLTStdBlack}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'GTWalsheimProBold';
    src: url(${GTWalsheimProBold}) format('truetype');
    font-weight: 900;
    font-style: normal;
  } 


  html,
  body {
    scroll-behavior: smooth;
  }
 
  body {
    font-size: 1rem;
    font-family: ${(props) => props.theme.typography.roman};
    background-color: black;
  }
  .bold {
    font-family: ${(props) => props.theme.typography.heavy} !important;
  }
  .light {
    font-family: ${(props) => props.theme.typography.light} !important;
  }
  .dark {
    color: ${(props) => props.theme.text.primary};
  }
  
  p {
    font-size: 0.9rem;
    font-family: ${(props) => props.theme.typography.light};
    color: ${(props) => props.theme.text.secondary};
  }
  
 
  // Scrollbar
 
  // #root, .scrollbox, .scrollGrid .k-grid-content, .custom-dialog .k-content, .pro-sidebar-layout {
  //   overflow-y: auto;
     
  //   &::-webkit-scrollbar {
  //     width: 6px;
  //     -webkit-border-radius: 10px;
  //     border-radius: 10px;
  //   }
  //   &::-webkit-scrollbar-thumb {
  //     background-color: rgba(0,0,0,.2);
  //     -webkit-border-radius: 10px;
  //     border-radius: 10px;
  //   }
  // }   

  // Responsive

  @media (max-width: 1366px) {
    html {
      font-size: 15px;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }


  @media (max-width: 425px) {
    html {
      font-size: 12.5px;
    }
  }
`;

export default GlobalStyle;
