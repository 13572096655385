import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { Button, LabelText, MainWrapper, Text } from '../../styles';

const StyledContainer = styled.div`
  background-color: black;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Wrapper = styled(MainWrapper)`
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-bottom: 10vh;

  @media (max-width: ${deviceSize.medium}px) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  width: 40%;
  text-align: left;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${deviceSize.medium}px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const Label = styled(LabelText)`
  margin-bottom: 0;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  color: white;
  font-size: 2.5rem;
  text-align: right;
  margin-bottom: 0;
  max-width: 450px;

  @media (max-width: ${deviceSize.medium}px) {
    max-width: 100%;
    text-align: left;
  }
`;

const Paragraph = styled(Text)`
  margin-top: 1rem;
  text-align: right;
  max-width: 450px;

  @media (max-width: ${deviceSize.medium}px) {
    max-width: 100%;
    text-align: left;
  }
`;

const SeeMoreBtn = styled(Button)`
  margin-top: 2rem;
`;

const ImageWrapper = styled.div`
  width: 50%;
  z-index: 0;
  opacity: 1;
  margin-right: 10%;

  @media (max-width: ${deviceSize.medium}px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
`;

export {
  StyledContainer,
  Title,
  Wrapper,
  SeeMoreBtn,
  ImageWrapper,
  Image,
  Paragraph,
  TextWrapper,
  Label,
};
