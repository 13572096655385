import React, { forwardRef, LegacyRef } from 'react';
import Slider from 'react-slick';
import {
  StyledContainer,
  Title,
  TextWrapper,
  Label,
  SlideItem,
  SlideColumn,
  SlideTitle,
  SlideLink,
  ArrowIcon,
  ArrowCircleRight,
  ArrowCircleLeft,
  Overlay,
  DownloadCVWrapper,
  DownloadCV,
  SlideSubtitle,
  SlideTextWrapper,
} from './index.styles';
import Arrow from '../../assets/images/arrow.png';
import Rently from '../../assets/images/portfolio_rently1.jpg';
import MedicalApp from '../../assets/images/portfolio_medical.jpg';
import Canala from '../../assets/images/portfolio_canala.jpg';
import Buddy from '../../assets/images/portfolio_buddy.jpg';
import Inclusion from '../../assets/images/portfolio_inclusion.jpg';
import Movies from '../../assets/images/portfolio_movies.jpg';
import Street from '../../assets/images/portfolio_street.jpg';
import Hours from '../../assets/images/portfolio_hours.jpg';
import Kompass from '../../assets/images/portfolio_kompass.jpg';
import type { Slide } from './types';

const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <ArrowCircleRight className={className} onClick={onClick}>
      <ArrowIcon src={Arrow} />
    </ArrowCircleRight>
  );
};

const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <ArrowCircleLeft className={className} onClick={onClick}>
      <ArrowIcon src={Arrow} />
    </ArrowCircleLeft>
  );
};

const Portfolio = forwardRef((props, ref) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const Slide = ({
    image1,
    image2,
    image3,
    link1,
    link2,
    link3,
    title1,
    title2,
    subtitle2,
    title3,
  }: Slide) => {
    return (
      <SlideItem>
        <SlideColumn>
          <SlideLink to={link1} bkg={image1}>
            <SlideTextWrapper>
              <SlideTitle>{title1}</SlideTitle>
            </SlideTextWrapper>
            <Overlay />
          </SlideLink>
        </SlideColumn>
        <SlideColumn>
          <SlideLink to={link2} bkg={image2}>
            <SlideTextWrapper>
              <SlideTitle>{title2}</SlideTitle>
              {subtitle2 && <SlideSubtitle>{subtitle2}</SlideSubtitle>}
            </SlideTextWrapper>
            <Overlay />
          </SlideLink>
          <SlideLink to={link3} bkg={image3}>
            <SlideTextWrapper>
              <SlideTitle>{title3}</SlideTitle>
            </SlideTextWrapper>
            <Overlay />
          </SlideLink>
        </SlideColumn>
      </SlideItem>
    );
  };
  return (
    <div ref={ref as LegacyRef<HTMLDivElement> | null} id="my-work">
      <StyledContainer>
        <TextWrapper>
          <Label>Portfolio</Label>
          <Title>Meet my work</Title>
        </TextWrapper>

        <Slider {...settings}>
          <Slide
            image1={Rently}
            image2={MedicalApp}
            image3={Buddy}
            link1="/"
            link2="/"
            link3="/"
            title1="In&Out Mobile App"
            title2="Medical Mobile App"
            subtitle2="Band Of Coders's project"
            title3="Buddy Trip Website"
          />
          <Slide
            image1={Canala}
            image2={Movies}
            image3={Inclusion}
            link1="/"
            link2="/"
            link3="/"
            title1="Lab. Canala Website"
            title2="Movies Mobile App"
            title3="Inclusion Website"
          />
          <Slide
            image1={Hours}
            image2={Street}
            image3={Kompass}
            link1="/"
            link2="/"
            link3="/"
            title1="Hours Web App"
            title2="Car Rental e-Commerce"
            title3="Car Rental Mobile Version"
          />
        </Slider>
        <DownloadCVWrapper>
          <DownloadCV href="/Florencia-Calomino_CV.pdf" target="_blank">
            Download CV
          </DownloadCV>
        </DownloadCVWrapper>
      </StyledContainer>
    </div>
  );
});

export default Portfolio;
