import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { MainWrapper, Text } from '../../styles';

const StyledHome = styled.div`
  background-color: black;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-width: ${deviceSize.medium}px) {
    padding-top: 3vh;
  }
`;

const Wrapper = styled(MainWrapper)`
  flex-direction: row;
  align-items: center;
  padding-bottom: 2rem;
`;

const Label = styled(Text)`
  text-align: center;
  margin-bottom: 0;
  margin: auto;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 0.6rem;
  line-height: 1.8;
  b {
    font-family: ${({ theme }) => theme.typography.heavy};
  }
`;

export { StyledHome, Wrapper, Label };
