import typography from './typography';
import { ThemeProps } from './types';

const lightTheme: ThemeProps = {
  typography,
  background: {
    primary: '#212835',
    secondary: '#151b27',
  },
  primary: '#D44343',
  shadow: '#0000000d',
  blue: '#004ED6',
  green: '#3EB711',
  lightBlue: '#189eff',
  red: '#D44343',
  orange: '#FFA524',
  lightGray: '#333b4c',
  middleGray: '#333b4c',
  white: '#ffffff',
  black: '#000000',
  text: {
    primary: '#d6dfec',
    secondary: '#787b81',
  },
};

export default lightTheme;
