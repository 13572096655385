import styled from 'styled-components';
import { deviceSize } from '../constants';

const MainWrapper = styled.div`
  width: 70%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: ${deviceSize.small}px) {
    width: 80%;
  }
`;

const Button = styled.button<{ alignRight?: boolean }>`
  font-family: ${({ theme }) => theme.typography.light};
  text-transform: uppercase;
  letter-spacing: 8px;
  color: white;
  background: transparent;
  border: none;
  text-align: left;
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => theme.primary};
  width: fit-content;
  padding: 0;
  margin: 0;
  padding-bottom: 0.6rem;
  transition: all 0.4s 0.2s;
  font-size: 0.8rem;

  &:hover {
    padding-left: ${({ alignRight }) => (alignRight ? '0' : '0.8rem')};
    padding-right: ${({ alignRight }) => (alignRight ? '0.8rem' : '0')};
    cursor: pointer;
  }

  @media (max-width: ${deviceSize.small}px) {
    font-size: 0.9rem;
  }
`;

const ButtonLink = styled.a<{ alignRight?: boolean }>`
  font-family: ${({ theme }) => theme.typography.light};
  text-transform: uppercase;
  letter-spacing: 8px;
  color: white;
  background: transparent;
  border: none;
  text-align: left;
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => theme.primary};
  width: fit-content;
  padding: 0;
  margin: 0;
  padding-bottom: 0.6rem;
  transition: all 0.4s 0.2s;
  text-decoration: none;
  font-size: 0.8rem;

  &:hover {
    padding-left: ${({ alignRight }) => (alignRight ? '0' : '0.8rem')};
    padding-right: ${({ alignRight }) => (alignRight ? '0.8rem' : '0')};
    cursor: pointer;
  }

  @media (max-width: ${deviceSize.small}px) {
    font-size: 0.9rem;
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.typography.light};
  font-size: 1rem;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
  line-height: 1.8rem;

  @media (min-width: ${deviceSize.extraLarge}px) {
    font-size: 1.1rem;
    line-height: 2rem;
  }
`;

const LabelText = styled.p`
  font-family: ${({ theme }) => theme.typography.heavy};
  font-size: 1.1rem;
  color: ${({ theme }) => theme.red};
  text-align: left;
  line-height: 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(55%, rgba(0, 0, 0, 0.3)),
    color-stop(100%, rgba(0, 0, 0, 0.3))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.3) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.3) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;

export { MainWrapper, Button, Text, LabelText, Overlay, ButtonLink };
