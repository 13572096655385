import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { ButtonLink, LabelText, MainWrapper, Text } from '../../styles';

const StyledContainer = styled.div`
  background-color: black;
  color: white;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Wrapper = styled(MainWrapper)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 10vh;
`;

const Label = styled(LabelText)`
  margin-bottom: 0;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  color: white;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0;
`;

const Paragraph = styled(Text)`
  margin-top: 1rem;
  text-align: center;
  max-width: 600px;

  @media (max-width: ${deviceSize.medium}px) {
    max-width: 100%;
  }
`;

const SeeMoreBtn = styled(ButtonLink)`
  margin-top: 2rem;
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 150px;
  margin-bottom: 2rem;
`;

const Link = styled.a`
  width: 22px;

  &:hover {
    img {
      opacity: 0.6;
    }
  }

  img {
    transition: all 0.4s;
    width: 100%;
  }
`;

export { StyledContainer, Title, Wrapper, SeeMoreBtn, Paragraph, Label, LinksWrapper, Link };
