import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import AppContainer from './containers/App';
import dark from './themes/darkTheme';
import GlobalStyle from './themes/globalStyles.ts';

const App = () => {
  return (
    <ThemeProvider theme={dark}>
      <Suspense fallback="">
        <GlobalStyle />
        <AppContainer />
      </Suspense>
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
