import styled from 'styled-components';
import { deviceSize } from '../../constants';

const StyledSequence = styled.div`
  height: 90vh;
  position: relative;
  background-color: black;

  @media (max-width: ${deviceSize.small}px) {
    height: 100%;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  p {
    opacity: 0;
    color: white;
    transition: all 2s;
  }
`;
export { StyledSequence, LoadingWrapper };
