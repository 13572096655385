import styled from 'styled-components';
import { deviceSize } from '../../constants';
import { MainWrapper, Text } from '../../styles';
import Bkg from '../Sequence/images/0000.jpg';

const StyledContainer = styled.div`
  background-color: transparent;
  color: white;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: ${deviceSize.medium}px) {
    position: relative;
    background-image: url(${Bkg});
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const Wrapper = styled(MainWrapper)`
  align-items: center;
  position: relative;
  padding-top: 5vh;
  padding-bottom: 10vh;
  position: relative;
  z-index: 1;

  @media (max-width: ${deviceSize.small}px) {
    padding-top: 0;
    padding-bottom: 5vh;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${deviceSize.small}px) {
    flex-wrap: wrap;
  }
`;

const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  top: 0;
  position: relative;

  @media (max-width: ${deviceSize.small}px) {
    width: 50%;
    margin-bottom: 0.5rem;
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff5c;
  padding: 2rem;
  border-radius: 100px;
  transition: all 0.8s;
  &:hover {
    border-color: white;
  }

  @media (min-width: ${deviceSize.extraLarge}px) {
    padding: 2.5rem;
  }

  @media (max-width: ${deviceSize.medium}px) {
    padding: 1.2rem;
  }

  @media (max-width: ${deviceSize.small}px) {
    padding: 1rem;
  }
`;

const CircleImg = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: ${deviceSize.extraLarge}px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${deviceSize.medium}px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: ${deviceSize.small}px) {
    width: 30px;
    height: 30px;
  }
`;

const CircleText = styled(Text)`
  color: white;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
  position: relative;

  @media (max-width: ${deviceSize.medium}px) {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  color: white;
  font-size: 2.5rem;
  margin: 0;
  flex: 1;
  text-align: right;
  padding-right: 1rem;
  z-index: 1;

  @media (max-width: ${deviceSize.medium}px) {
    text-align: left;
  }
`;

const Paragraph = styled(Text)`
  display: flex;
  flex: 1;
  margin: 0;
  padding-left: 1rem;
  z-index: 1;

  @media (max-width: ${deviceSize.medium}px) {
    margin-top: 1rem;
    padding-left: 0;
  }
`;

const UnderText = styled(Text)`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  font-size: 5rem;
  position: absolute;
  z-index: 0;
  opacity: 0.4;
  line-height: 5.5rem;
  top: 2vh;
  right: 0;
  -webkit-text-stroke: 2px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #5b697e;
  text-transform: uppercase;
  text-align: right;

  @media (min-width: ${deviceSize.extraLarge}px) {
    font-size: 7rem;
    line-height: 8rem;
    top: -2vh;
  }

  @media (max-width: ${deviceSize.medium}px) {
    position: relative;
    font-size: 3.5rem;
    line-height: 4rem;
    text-align: left;
    align-self: flex-start;
  }

  @media (max-width: ${deviceSize.small}px) {
    font-size: 3rem;
    line-height: 3rem;
    -webkit-text-stroke: 1.5px;
  }
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.primary};
  display: inline-block;
  position: relative;
  left: 5px;
  bottom: 0;

  @media (max-width: ${deviceSize.medium}px) {
    width: 8px;
    height: 8px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(100%, rgba(0, 0, 0, 0.6))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 55%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;

export {
  StyledContainer,
  Wrapper,
  IconsWrapper,
  Circle,
  CircleImg,
  CircleWrapper,
  CircleText,
  TextWrapper,
  Title,
  Paragraph,
  UnderText,
  Dot,
  Overlay,
};
