import React from 'react';
import { StyledHeader, Wrapper, Label, Logo, MenuWrapper, Link } from './index.styles';
import LogoImg from '../../assets/images/ojo-critico_logo.png';
import Email from '../../assets/images/email.png';
import Github from '../../assets/images/github.png';
import Linkedin from '../../assets/images/linkedin.png';

const Header = () => {
  return (
    <StyledHeader>
      <Wrapper>
        <a href="/">
          <Logo src={LogoImg} />
        </a>
        <MenuWrapper>
          <Link
            href="https://www.linkedin.com/in/florenciacalomino/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} alt="Linkedin" />
          </Link>
          <Link
            href="https://github.com/florcalomino?tab=repositories"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Github} alt="Github" />
          </Link>
          <Link href="mailto:florcalomino@icloud.com" target="_blank" rel="noreferrer">
            <img src={Email} alt="Send an email" />
          </Link>
        </MenuWrapper>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;
