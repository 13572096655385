import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeContainer from '../HomeContainer';

const AppContainer = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/portfolio" element={<HomeContainer />} />
      </Routes>
    </Router>
  );
};

export default AppContainer;
