import styled from 'styled-components';
import { ButtonLink, LabelText, MainWrapper } from '../../styles';
import { Link } from 'react-router-dom';
import { Paragraph } from '../Home/index.styles';
import { deviceSize } from '../../constants';

const StyledContainer = styled.div`
  background-color: black;
  position: relative;
  padding: 10vh 0 0;
  margin-bottom: 10vh;

  .slick-arrow {
    z-index: 1;
  }
  .slick-list {
    z-index: 0;
  }

  .slick-next:before {
    content: '';
    right: 0;
  }
  .slick-prev:before {
    content: '';
    left: 0;
  }
  .slick-disabled {
    opacity: 0;
    cursor: default;
  }

  @media (max-width: ${deviceSize.medium}px) {
    padding: 3.5vh 0 0;
    margin-bottom: 0;
  }
`;

const TextWrapper = styled(MainWrapper)`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;

  @media (max-width: ${deviceSize.medium}px) {
    position: relative;
    margin-bottom: 2rem;
  }
`;

const Label = styled(LabelText)`
  margin-bottom: 0;
  margin-top: 2.5rem;

  @media (max-width: ${deviceSize.medium}px) {
    margin-top: 0;
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.GTWalsheimProBold};
  color: white;
  font-size: 2.5rem;
  text-align: left;
  margin: 0;
`;

const SlideItem = styled(MainWrapper)`
  display: flex;
  flex-direction: row;

  @media (max-width: ${deviceSize.medium}px) {
    flex-direction: column;
  }
`;

const SlideColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &:first-child {
    margin-right: 10px;
  }

  @media (max-width: ${deviceSize.medium}px) {
    &:first-child {
      margin-right: 0;
    }
  }
`;

const SlideLink = styled(Link)<{ bkg: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  aspect-ratio: 1.5;
  margin: 5px 0;
  border-radius: 15px;
  background-image: ${({ bkg }) => `url(${bkg})`};
  background-size: cover;
  text-decoration: none;
  position: relative;

  &:hover p,
  &:hover > div {
    opacity: 1;
  }
`;

const SlideTextWrapper = styled.div`
  z-index: 1;
  padding-bottom: 2rem;
  padding-left: 2rem;
`;

const SlideTitle = styled(Paragraph)`
  color: white;
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.heavy};
  font-size: 1.5rem;
  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: 1;
  margin: 0;
`;

const SlideSubtitle = styled(Paragraph)`
  color: white;
  text-decoration: none;
  font-family: ${({ theme }) => theme.typography.light};
  font-size: 0.9rem;
  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: 1;
  margin: 0;
`;

const ArrowCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary};
  transition: border-color 0.6s;

  &:hover {
    border-color: ${({ theme }) => `${theme.primary}ab`};
  }

  @media (max-width: ${deviceSize.medium}px) {
    width: 60px;
    height: 60px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  background: -webkit-gradient(
    left bottom,
    left top,
    color-stop(0%, rgba(0, 0, 0, 0.8)),
    color-stop(80%, rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  background: -ms-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
`;

const ArrowCircleRight = styled(ArrowCircle)`
  right: 2vw;
`;

const ArrowCircleLeft = styled(ArrowCircle)`
  transform: rotate(-180deg);
  left: 2vw;
`;

const ArrowIcon = styled.img`
  width: 8px;

  @media (max-width: ${deviceSize.medium}px) {
    width: 6px;
  }
`;
const DownloadCVWrapper = styled(MainWrapper)`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: ${deviceSize.medium}px) {
    position: relative;
    margin-bottom: 2rem;
  }
`;
const DownloadCV = styled(ButtonLink)`
  margin-bottom: 4rem;

  @media (max-width: ${deviceSize.medium}px) {
    margin: 2rem auto 2rem auto;
  }
`;

export {
  StyledContainer,
  Title,
  TextWrapper,
  Label,
  SlideItem,
  SlideColumn,
  SlideTitle,
  SlideLink,
  ArrowIcon,
  ArrowCircleRight,
  ArrowCircleLeft,
  Overlay,
  DownloadCV,
  DownloadCVWrapper,
  SlideSubtitle,
  SlideTextWrapper,
};
