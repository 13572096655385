import React, { forwardRef, LegacyRef, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import {
  StyledContainer,
  Title,
  Wrapper,
  SeeMoreBtn,
  ImageWrapper,
  Image,
  Paragraph,
  TextWrapper,
  Label,
} from './index.styles';
import MobileApps from '../../assets/images/skills-mobile-apps.jpg';

const SkillSet = forwardRef((props, ref) => {
  const imageRef = useRef(null);

  useEffect(() => {
    gsap.from('#image-wrapper', {
      x: -180,
      opacity: 0,
      duration: 2,
      ease: 'power3',
      delay: 0.8,
    });
  }, []);

  return (
    <div ref={ref as LegacyRef<HTMLDivElement> | null}>
      <StyledContainer>
        <Wrapper>
          <ImageWrapper ref={imageRef} id="image-wrapper">
            <Image src={MobileApps} alt="Ojo Crítico Mobile Apps" />
          </ImageWrapper>

          <TextWrapper>
            <Label>Skills & Experience</Label>
            <Title>I'm a Frontend developer who used to be a graphic designer</Title>
            <Paragraph>
              I have a degree in graphic design and more than 10 years of experience working on that
              field, I use all my knowledge to achieve pixel-perfect apps. Following the best UX
              standards and clean code practices.
            </Paragraph>
            <SeeMoreBtn alignRight onClick={() => (window.location.href = '/#my-work')}>
              See my work
            </SeeMoreBtn>
          </TextWrapper>
        </Wrapper>
      </StyledContainer>
    </div>
  );
});

export default SkillSet;
