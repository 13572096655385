import React, { forwardRef, LegacyRef, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import {
  StyledHome,
  Title,
  Wrapper,
  SubTitle,
  SeeMoreBtn,
  Dot,
  EyesWrapper,
  EyesImage,
  Paragraph,
  TextWrapper,
} from './index.styles';
import Eyes from '../../assets/images/eyes.jpg';

const Home = forwardRef((props, ref) => {
  const imageRef = useRef(null);

  useEffect(() => {
    gsap.from('#eyes-wrapper', {
      opacity: 0,
      duration: 2,
      ease: 'none',
    });
  }, []);

  return (
    <div ref={ref as LegacyRef<HTMLDivElement> | null}>
      <StyledHome>
        <Wrapper>
          <TextWrapper>
            <Title>
              Hi, I'm Flor
              <Dot />
            </Title>
            <SubTitle>UX Developer</SubTitle>
            <Paragraph>
              A passionate Frontend Developer who enjoys developing pixel-perfect UI and bug-free
              apps. Love working with complex functionalities, data management and well performed
              apps. I worked with several clients from all around the world.
            </Paragraph>
            <SeeMoreBtn
              id="skills-and-experience"
              onClick={() => (window.location.href = '/#skills-and-experience')}
            >
              Scroll for more
            </SeeMoreBtn>
          </TextWrapper>
          <EyesWrapper ref={imageRef} id="eyes-wrapper">
            <EyesImage src={Eyes} alt="Ojo Crítico Eyes" />
          </EyesWrapper>
        </Wrapper>
      </StyledHome>
    </div>
  );
});

export default Home;
