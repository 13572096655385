import React from 'react';
import { Wrapper, Bar } from './index.styles';

const ProgressBar = ({ style }: { style: any }) => {
  return (
    <Wrapper>
      <Bar style={style} />
    </Wrapper>
  );
};
export default ProgressBar;
