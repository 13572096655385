import React from 'react';
import { StyledHome, Wrapper, Label } from './index.styles';

const Footer = () => {
  return (
    <StyledHome>
      <Wrapper>
        <Label>
          Made from scratch by <b>Florencia Calomino</b>
        </Label>
      </Wrapper>
    </StyledHome>
  );
};

export default Footer;
